<template>
    <div class="createaudio" :class="isFull?'audio_box':''" :style="globalStyle" id="createaudio">
        <div class="main" v-loading="audioLoading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <!-- 顶部的内容 -->
            <div class="topBox d-f a-c j-c-s">
                <div class="topBoxMain d-f a-c j-c-s">
                    <div class="topBoxMain-l d-f a-c">
                        <!-- 图标 -->
                        <div class="scaleimg" @click="setFull">
                            <img src="@/assets/images/createaudio/icon1.png" alt="" v-show="isFull" />
                            <img src="@/assets/images/createaudio/icon2.png" alt="" v-show="!isFull" />
                        </div>
                        <!-- 输入框 -->
                        <div class="name" v-show="!isEdit">
                            {{ name }}
                        </div>
                        <!-- name输入框 -->
                        <div class="nameinput" v-show="isEdit">
                            <el-input v-model="inputName" placeholder="请输入视频名称" @keyup.enter.native="$event.target.blur()"
                                @blur="confimName"></el-input>
                        </div>
                        <!-- 编辑的图标 -->
                        <i class="el-icon-edit editicon" v-show="!isEdit" @click="isEdit = true, inputName = name"></i>
                    </div>
                    <div class="topBoxMain-r d-f a-c">
                        <!-- 保存 -->
                        <div class="save d-f a-c">
                            {{ saveTime }}
                            <img class="saveimg" @click="saveBtn" src="@/assets/images/createaudio/icon3.png" alt="" />
                        </div>
                        <!-- 显示音频剩余的市场 -->
                        <div class="time">
                            音频剩余时长余额：
                            <span v-if="$utils.formatSeconds(audio_duration, 3).h"> {{ $utils.formatSeconds(audio_duration, 3).h }}<span class="span_txt">小时</span></span>
                            <span v-if="$utils.formatSeconds(audio_duration, 3).m"> {{ $utils.formatSeconds(audio_duration, 3).m }}<span class="span_txt">分</span></span>
                            <span>{{ $utils.formatSeconds(audio_duration, 3).s }}<span class="span_txt">秒</span></span>
                        </div>
                        <!-- 充值 -->
                        <div class="payButton d-f a-c" @click="investBtn(1)">
                            <img src="@/assets/images/createaudio/icon4.png" alt="" />
                            充值
                        </div>
                        <!-- 合成 -->
                        <div class="compoundBUtton" @click="syntheticAudio">合成</div>
                    </div>
                </div>
            </div>
            <!-- 左边的内容 -->
            <div class="leftBox">
                <div class="leftBoxMain">
                    <div class="item d-f a-c j-c" :class="leftIndex==item.type?'item_active':''" v-for="(item, lx) in leftMenu" :key="lx" @click="leftOpenDraw(item.type)">
                        <img :src="item.icon" alt="" />
                        <p class="name">{{ item.name }}</p>
                    </div>
                    <!-- 这个地方显示引导的区域 -->
                    <div class="guide" v-show="showGuide && audioTipShow==1">
                        <p>点击切换声音模型</p>
                        <!-- 按钮 -->
                        <div class="buttonBox d-f a-c">
                            <div class="button1" @click="guideClose(1)">不再提示</div>
                            <div class="button2" @click="guideClose(2)">知道了</div>
                        </div>
                        <!-- 关闭的图标 -->
                        <div class="close" @click="guideClose(2)">
                            <i class="el-icon-close"></i>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 中间的内容 -->
            <div class="centerBox">
                <div class="topbox">
                    <div class="editBox scroll_bar_y" ref="editor" @click="closeRightMenu" @blur="blurBtn($event)" id="editor"
                        @mouseup="mousedownEvent" v-html="inittxt" contenteditable="true" @mousedown="mousedEvent"
                        @contextmenu.prevent="handlePaste($event)"></div>
                    <!-- 右键的菜单 -->
                    <div class="rightMenu_box" v-show="checkRightMenuShow"></div>
                    <div class="rightMenu" v-show="checkRightMenuShow">
                        <div class="rightMenuL">
                            <div class="item item1" @mouseover="setShow('inputbox')">
                                自定义
                            </div>
                            <div class="item" v-show="checkMultitpinyinShow" @mouseover="setShow('multitpinyinbox')">
                                多音字
                            </div>
                            <div class="item" @mouseover="setShow(' ')" @click="deleteChild">
                                取消标记
                            </div>
                        </div>
                        <div class="rightMenuR">
                            <!-- 显示输入标记的框 -->
                            <div class="inputbox" v-show="controlsShow.inputbox">
                                <el-input v-model="keyword" size="mini"></el-input>
                                <div class="confimKeyword" @click="setSpell(keyword)">确定</div>
                            </div>
                            <!-- 显示多音字 -->
                            <div class="multitpinyinbox" v-show="controlsShow.multitpinyinbox">
                                <div class="item" v-for="(item, z) in multitpinyinList" :key="z" @click="setSpell(item)">
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 右边的box -->
            <div class="rightBox">
                <div class="rightMain">
                    <div class="item d-f a-c j-c" :class="rightIndex==item.type?'item_active':''" v-for="(item, rx) in rightMenu" :key="rx" @click="rightOpenDraw(item.type)">
                        <img :src="item.icon" alt="" />
                        <p class="name">{{ item.name }}</p>
                    </div>
                </div>
            </div>
            <!-- 底部的box -->
            <div class="bottomBox">
                <div class="bottomBox-one">
                    <div class="bot_btn">
                        <el-popover placement="top" popper-class="customdelay" trigger="click">
                            <div class="delay">
                                <div class="item" @click="delayselect(0.5)">0.5秒</div>
                                <div class="item" @click="delayselect(1)">1秒</div>
                                <div class="item" @click="delayselect(2)">2秒</div>
                                <div class="iteminput">
                                    <div class="iteminputbox">
                                        <el-input v-model="delaytime" @keyup.enter.native="$event.target.blur()"
                                            @blur="delaychage"></el-input>
                                    </div>
                                    <span class="label">秒</span>
                                </div>
                            </div>
                            <div class="btn_pause" slot="reference">插入停顿</div>
                        </el-popover>
                        <div class="btn_pinyin" @click="checkTxt">
                            {{ pinyinShow?'取消检查':'读音检查' }}
                            <el-tooltip effect="dark" placement="top-end">
                                <div slot="content" class="item_txt">
                                    点击后将自动高亮数字、多音字，右击高亮文字后可<br />以编辑读法，标记读音；若要编辑未高亮的文字，选<br />中文字即可编辑
                                </div>
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="bottomBox-two d-f a-c">
                    <div class="two-pic">
                        <el-image class="img" :src="imageUrl" fit="cover"></el-image>
                        <el-upload class="img-uploader" action="#" :show-file-list="false" :http-request="uploadimg">
                            <div class="img_txt">更换封面</div>
                        </el-upload>
                        <div class="upload_loading" v-if="imgLoading"><i class="el-icon-loading"></i></div>
                    </div>
                    <div class="two-status d-f">
                        <!-- 缓冲 -->
                        <div class="statusicon">
                            <!-- loading -->
                            <i class="el-icon-loading" v-show="audioStatus==1"></i>
                            <!-- play -->
                            <img src="@/assets/images/createaudio/icon12.png" alt="" @click="playMusic" v-show="audioStatus==3" />
                            <!-- pause -->
                            <img src="@/assets/images/createaudio/icon13.png" alt="" @click="pauseMusic"  v-show="audioStatus==2" />
                        </div>
                    </div>
                    <!-- 进度条 -->
                    <div class="two-progress f1">
                        <el-slider :disabled="audioCtx?false:true" v-model="playTime" :step="0.0001" @change="changeSlider" :show-tooltip="false"> </el-slider>
                    </div>
                    <!-- 时间 -->
                    <div class="two-time">
                        {{ this.$utils.timeToMinute(audioInfo.currentTime) }} /
                        {{ this.$utils.timeToMinute(audioInfo.duration) }}
                    </div>
                    <!-- 试听 -->
                    <div class="two-button">
                        <div class="buttonplay d-f a-c" @click="startPlay()">
                            试听
                            <img src="@/assets/images/createaudio/icon14.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- 左侧的弹窗 -->
        <ModalSelect ref="model" :navList="navList" :list="list" @nav="navTabBtn" @change="changeBtn" @play="pauseMusic" @close="closeLeft"></ModalSelect>
        <!-- 底部的弹窗 -->
        <ModalBottom ref="modelbottom" @txt="txtBtn" @close="closeLeft"></ModalBottom>
        <!-- 右侧的弹窗 -->
        <ModelRight ref="modelright" :sampleRate="sample_rate" :modelInfo="modelInfo" :rightSettingForm="rightSettingForm" @close="closeRight"></ModelRight>
        <!-- 充值 -->
        <invest-money ref="InvestMoney" @renovate='getParams' />
    </div>
</template>
<script>
import { mapState } from "vuex"
import ModalSelect from "./components/model";
import ModalBottom from "./components/text";
import ModelRight from "./components/setting";
import InvestMoney from '@/components/invest_money/invest_money.vue'//充值
import { AUDIOCONTRALS } from "@/mixin/audio.js";
import { CREATEAUDIO } from "@/mixin/createaudio.js";
export default {
    mixins: [AUDIOCONTRALS, CREATEAUDIO],
    components: {
        ModalSelect,
        ModalBottom,
        ModelRight,
        InvestMoney,
    },
    props: {},
    data() {
        return {
            id:'',
            // 是否全屏幕
            isFull: false, 
            // 名称 是否为编辑状态
            isEdit: false,
            inputName:'',
            // 名称
            name: "", 
            // 音频时长
            audio_duration: 0,
            // 显示引导区域
            showGuide: true,
            //设置内容为全屏幕的dom节点
            elem: null,
            // 模型分类列表
            navList:[],
            // 模型列表
            list:[],
            // 最近使用
            modelList:[],
            // 当前选中
            modelChangeList:[],
            // 选中的模型
            modelInfo: { },
            // 右侧语音设置的form
            rightSettingForm: {},
            // 采样率
            sample_rate:[],
            // 左侧的菜单
            leftMenu: [
                {
                    name: "模型",
                    icon: require("@/assets/images/createaudio/icon5.png"),
                    type: "model",
                },
                {
                    name: "文本",
                    icon: require("@/assets/images/createaudio/icon6.png"),
                    type: "modelbottom",
                },
            ],
            leftIndex:null,
            // 右侧的菜单
            rightMenu: [
                {
                    name: "语音设置",
                    icon: require("@/assets/images/createaudio/icon7.png"),
                    type: "modelright",
                },
            ],
            rightIndex:null,
            // 底部
            imgLoading:false,
            // 音频头像
            imageUrl:require('@/assets/images/createaudio/icon11.png'),
        };
    },
    created() {
        if (this.$route.params.id) {
            var name = '音频制作-' + this.$route.query.name
            var key = this.$route.meta.key + '_' + this.$route.params.id
            this.id = this.$route.params.id
            this.$store.dispatch('tabsArr/getName', { name, key })
            this.getInfo()
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.elem = document.getElementById("createaudio");
        });
    },
    watch: {},
    computed: {
        ...mapState({
            audioTipShow: state => state.audioTipShow, // 创建音频提示
        }),
    },
    methods: {
        // 获取详情
        getInfo(){
            var that = this
            that.$creationApi.getAudioDetails(this.id).then(res => {
                if (res.code == 1000) {
                    // 名称
                    that.name = res.result.name
                    // 选中得模型
                    that.modelInfo = {
                        id:res.result.tts_list_id,
                        name:res.result.tts_list_name,
                    }
                    // 右侧语音设置的form
                    this.rightSettingForm = {
                        speed: res.result.speed_rate || 0,
                        volume: res.result.volume || 0,
                        intonation: res.result.pitch || 0,
                        rate: res.result.sample_rate || '',
                    }
                    // 内容
                    that.inittxt = res.result.content
                    // that.inittxt = '庆历四年春，滕子京谪守2222巴陵郡。越明年，政通人和，百废具兴。乃重修岳阳楼，增其旧制，刻唐贤今人诗赋于其上。属予作文以记之。'
                    // 获取参数
                    that.getParams()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 获取模型参数
        getParams(){
            var that = this
            var params = {
                id: that.modelInfo.id
            }
            that.$audioApi.ttsCreateParams(params).then(res => {
                if (res.code == 1000) {
                    // 模型分类列表
                    that.navList = res.result.labels
                    // 模型最近使用列表
                    that.modelList = res.result.recent
                    // 模型当前选中列表
                    that.modelChangeList = res.result.tts_list
                    // 音频时长
                    that.audio_duration = res.result.balance.audio_duration
                    // 采样率
                    that.sample_rate = res.result.sample_rate
                    // 音频模型头像
                    that.imageUrl = res.result.default_tts.avatar
                    // 处理模型选中
                    that.modelChange()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 处理模型选中
        modelChange(){
            var arr = []
            var obj = this.navList.find(item=>item.selected==1 && item.id==0)
            if(obj){
                arr = this.modelList
            }else{
                arr = this.modelChangeList
            }
            arr.forEach(ite=>{
                ite.show = false
                ite.selected=0
                ite.audioStatus = 3
                if(ite.children && ite.children.length){
                    ite.children.forEach(it=>{
                        it.audioStatus = 3
                        if(it.id == this.modelInfo.id){
                            it.selected=1
                            ite.selected=1
                            ite.show = true
                        }else{
                            it.selected=0
                        }
                    })
                }
            })
            this.list = arr
        },
        // 选中模型
        changeBtn(obj){
            if(obj.type==1){
                // 一级
                this.list.forEach(item=>{
                    if(item.id == obj.item.id){
                        item.selected = 1
                        this.modelInfo = {
                            id:item.id,
                            name:item.name,
                        }
                    }else{
                        item.selected = 0
                    }
                    if(item.children && item.children.length){
                        item.children.forEach(ite=>{
                            ite.selected = 0
                        })
                    }
                })
            }else{
                // 二级
                this.list.forEach(item=>{
                    if(item.id == obj.item.id){
                        item.selected = 1
                        if(item.children && item.children.length){
                            item.children.forEach(ite=>{
                                if(ite.id == obj.ite.id){
                                    ite.selected = 1
                                    this.modelInfo = {
                                        id:ite.id,
                                        name:ite.name,
                                    }
                                }else{
                                    ite.selected = 0
                                }
                            })
                        }
                    }else{
                        item.selected = 0
                        if(item.children && item.children.length){
                            item.children.forEach(ite=>{
                                ite.selected = 0
                            })
                        }
                    }
                })
            }
        },
        // 切换
        navTabBtn(obj){
            var that = this
            if(obj.category_id==0){
                that.navList.forEach(item=>{
                    if(item.id==0){
                        item.selected = 1
                    }else{
                        item.selected = 0
                    }
                })
                that.modelChange()
                return
            }else{
                that.$refs.model.getLoading()
                that.$audioApi.ttsListSelect(obj).then(res => {
                    that.$refs.model.getLoading()
                    if (res.code == 1000) {
                        that.modelChangeList = res.result || []
                        if(obj.category_id){
                            that.navList.forEach(item=>{
                                if(item.id==obj.category_id){
                                    item.selected = 1
                                }else{
                                    item.selected = 0
                                }
                            })
                        }
                        that.modelChange()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 确定更改名称
        confimName() {
            var that = this
            if(that.inputName==''){
                that.$warMsg('请输入音频名称')
                return
            }
            var params = {
                name: that.inputName
            }
            that.$audioApi.putTtsCreate(params,that.id).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.name = that.inputName
                    that.isEdit = false
                    that.inputName = ''
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 充值
        investBtn(type) {
            this.$refs.InvestMoney.openDialogBtn(type)
        },
        // 打开左弹窗
        leftOpenDraw(type) {
            if(this.leftIndex){
                this.$refs[this.leftIndex].openDraw()
            }
            this.leftIndex = type
            this.$refs[type].openDraw()
        },
        // 关闭左弹窗
        closeLeft(){
            this.leftIndex = null
        },
        // 上传文件回调
        txtBtn(txt){
            this.pinyinShow = false
            this.inittxt = txt
        },
        // 打开右弹窗
        rightOpenDraw(type){
            this.rightIndex = type
            this.$refs[type].openDraw();
        },
        // 关闭右弹窗
        closeRight(){
            this.rightIndex = null
        },
        // 输入时间的验证
        delaychage(e) {
            var reg = /^([0-9]|10)$/;
            this.delayselect(reg.test(e.target.value) ? e.target.value : 10)
        },
        // 设置为全屏幕
        setFull() {
            this.isFull = !this.isFull;
        },
        // 模型提示
        guideClose(val){
            if(val==1){
                // 不在提示
                this.$store.commit('AUDIO_TIP_SHOW',2)
            }else{
                // 当前页面关闭
                this.showGuide = false
            }
        },
        // 上传封面
        uploadimg(item) {
            var is_img = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg']
            var self = this
            // 限制图片格式
            const isJPG = is_img.includes(item.file.type)
            // 限制图片大小
            const isLt2M = item.file.size / 1024 / 1024 <= 3;
            if (!isJPG) {
                self.$errMsg('上传图片只能是 gif, JPG, JPEG, PNG 格式!');
            }
            if (!isLt2M) {
                self.$errMsg('上传图片大小不能超过 3MB!');
            }
            if (isJPG && isLt2M) {
                let newFile = new FormData()
                newFile.append('type', 4); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
                newFile.append('file', item.file);
                self.imgLoading = true
                self.$publicApi.uploadImg(newFile).then(res => {
                    if (res.code == 1000) {
                        self.imageUrl = res.result.url
                    } else {
                        self.$errMsg(res.message)
                    }
                    self.imgLoading = false
                })
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.d-f {
    display: flex;
}

.a-c {
    align-items: center;
}

.j-c-s {
    justify-content: space-between;
}

.f1 {
    flex: 1;
}

.createaudio {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 700px;
    padding: 15px;
    border-radius: 10px;
    background: #101314;
    overflow: hidden;

    .main {
        position: relative;
        height: 100%;

        .topBox {
            height: 64px;
            background: #1b1d1f;
            border-radius: 10px 10px 0px 0px;
            padding: 0 20px;
            width: 100%;

            .topBoxMain {
                width: 100%;

                .topBoxMain-l {
                    .scaleimg {
                        cursor: pointer;

                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .name {
                        margin-left: 20px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #ffffff;
                    }

                    .nameinput {
                        width: 260px;
                        height: 32px;
                        margin-left: 20px;

                        ::v-deep .el-input__inner {
                            height: 32px;
                            line-height: 32px;
                            background: #3C4148;
                            color: #fff;
                            border: none;
                        }
                    }

                    .editicon {
                        color: #aeaeae;
                        margin-left: 20px;
                        cursor: pointer;
                        padding-top: 2px;
                    }
                }

                .topBoxMain-r {
                    .save {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;

                        .saveimg {
                            cursor: pointer;
                            width: 33px;
                            height: 32px;
                            margin-left: 13px;
                        }
                    }

                    .time {
                        background-color: rgba(255, 95, 95, 0.19);
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.9);
                        padding: 3px 18px 6px;
                        border-radius: 22px;
                        position: relative;
                        margin-left: 40px;

                        span {
                            color: #ff5f5f;
                            font-size: 18px;
                            .span_txt{
                                padding: 0 5px;
                                font-size: 14px;
                                color: rgba(255, 255, 255, 0.9);
                            }
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            width: 1px;
                            height: 30px;
                            border-left: 1px solid #313131;
                            top: 3px;
                            left: -20px;
                        }
                    }

                    .payButton {
                        margin-left: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        background: linear-gradient(90deg, #ff5f5f 0%, #ff7432 100%);
                        border-radius: 4px;
                        padding: 4px 12px;
                        cursor: pointer;
                        user-select: none;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .compoundBUtton {
                        margin-left: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        padding: 6px 18px;
                        background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
                        border-radius: 4px;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }

        .leftBox {
            background: #1b1d1f;
            border-radius: 0px 0px 0px 10px;
            width: 76px;
            position: absolute;
            top: 68px;
            left: 0;
            height: calc(100% - 68px);

            .leftBoxMain {
                padding-top: 30px;
                position: relative;

                .guide {
                    position: absolute;
                    width: 248px;
                    height: 94px;
                    background: #364251;
                    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
                    left: 90px;
                    top: 0;
                    z-index: 9;
                    padding: 16px;
                    border-radius: 4px;

                    p {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                    }

                    .close {
                        position: absolute;
                        top: 10px;
                        right: 10px;

                        i {
                            color: #98999b;

                            &:hover {
                                color: #007aff;
                                cursor: pointer;
                            }
                        }
                    }

                    .buttonBox {
                        margin-top: 16px;
                        display: flex;
                        justify-content: flex-end;

                        .button1 {
                            cursor: pointer;
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ffffff;
                            padding: 4px 10px;
                            border-radius: 4px;
                            border: 1px solid #d7d7d7;
                            display: inline-block;
                        }

                        .button2 {
                            cursor: pointer;
                            margin-left: 6px;
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ffffff;
                            background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
                            border-radius: 4px;
                            padding: 5px 10px;
                            display: inline-block;
                        }
                    }

                    &::before {
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 20px solid #364251;
                        position: absolute;
                        left: -10px;
                    }
                }

                .item {
                    flex-direction: column;
                    margin-bottom: 40px;
                    padding: 15px 0;
                    cursor: pointer;

                    .name {
                        margin-top: 4px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                    }

                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }
        .rightBox {
            position: absolute;
            top: 68px;
            right: 0;
            width: 84px;
            height: calc(100% - 211px - 68px);
            background: #1e232a;

            .rightMain {
                padding-top: 30px;

                .item {
                    flex-direction: column;
                    margin-bottom: 40px;
                    padding: 15px 0;
                    cursor: pointer;

                    .name {
                        margin-top: 5px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                    }

                    img {
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }

        .bottomBox {
            height: 208px;
            background: #1b1d1f;
            border-radius: 0px 0px 10px 0px;
            position: absolute;
            left: 79px;
            bottom: 0;
            width: calc(100% - 79px);
            padding: 0 20px;

            .bottomBox-one {
                margin-top: 20px;

                .bot_btn {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .btn_pause {
                        cursor: pointer;
                        width: 64px;
                        height: 28px;
                        background: rgba(48, 77, 242, 0.5);
                        border-radius: 4px;
                        border: 1px solid #2e4bf2;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 26px;
                        text-align: center;
                    }

                    .btn_pinyin {
                        margin-left: 20px;
                        cursor: pointer;
                        width: 76px;
                        height: 28px;
                        background: rgba(255, 95, 95, 0.48);
                        border-radius: 4px;
                        border: 1px solid #ff5f5f;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 26px;
                        text-align: center;

                        .el-icon-question {
                            line-height: 28px;
                        }
                    }
                }
            }

            .bottomBox-two {
                background: #0d1011;
                border-radius: 60px;
                padding: 15px;
                margin-top: 20px;

                .two-pic {
                    cursor: pointer;
                    position: relative;
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    overflow: hidden;
                    .img {
                        width: 90px;
                        height: 90px;
                    }
                    .img-uploader{
                        width: 90px;
                        height: 90px;
                        padding: 8px;
                        

                        .img_txt{
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            width: 90px;
                            height: 90px;
                            font-size: 14px;
                            background: rgba(0,0,0,0.5);
                            color: #fff;
                            display: none;
                            
                        }
                    }
                    .upload_loading{
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .el-icon-loading{
                            font-size: 24px;
                            color: #fff;
                        }
                    }
                }
                .two-pic:hover{
                    .img_txt{
                        display: flex;
                        flex-direction: column;
                        align-content: center;
                        justify-content: center;
                    }
                }

                .two-status {
                    width: 90px;
                    justify-content: center;

                    .statusicon {
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        background-color: #ff7b62;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        i {
                            color: #fff;
                            font-size: 25px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .two-progress {
                    ::v-deep {
                        .el-slider__bar {
                            background-color: #fff;
                        }

                        .el-slider__runway {
                            background: rgba(255, 255, 255, 0.4);
                        }

                        .el-slider__button {
                            border: 2px solid #fff;
                            background-color: #2e4bf2;
                        }
                    }
                }

                .two-time {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    margin-left: 30px;
                    width: 110px;
                }

                .two-button {
                    margin-left: 30px;

                    .buttonplay {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        padding: 12px 32px;
                        background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
                        border-radius: 22px;
                        cursor: pointer;

                        img {
                            width: 18px;
                            height: 18px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        
        .item_active{
            background: #2E363F;
        }

        .centerBox {
            position: absolute;
            top: 68px;
            left: 79px;
            right: 87px;
            bottom: 211px;
            background-color: #000000;

            .topbox {
                width: 100%;
                height: 100%;
                background-color: #0b0b0c;
                padding: 5px;
                // position: relative;

                .editBox {
                    color: #fff;
                    line-height: 30px;
                    height: 100%;
                    padding: 10px;
                    overflow-y: scroll;

                    &:focus-visible {
                        outline: none;
                    }
                }
                .rightMenu_box{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0); 
                }
                .rightMenu {
                    position: absolute;
                    left: var(--left);
                    top: var(--top);
                    z-index: 2;
                    padding: 5px 10px;
                    background: rgba(255,255,255,1);
                    border-radius: 10px;
                    border: 1px solid #6e757e;
                    transition: height 0.3s ease;
                    display: flex;

                    .rightMenuL {
                        .item {
                            color: #333;
                            font-size: 14px;
                            line-height: 35px;
                            white-space: nowrap;
                            cursor: pointer;

                            &:hover {
                                color: #2E4BF2;
                            }
                        }
                    }

                    .rightMenuR {
                        margin-left: 15px;
                        overflow: hidden;

                        .inputbox {
                            width: 65px;
                            padding-top: 5px;

                            .confimKeyword {
                                margin-top: 10px;
                                font-size: 14px;
                                text-align: right;
                                color: #2E4BF2;
                                cursor: pointer;
                            }

                            ::v-deep .el-input__inner {
                                width: inherit;
                                background: rgba(46,75,242,0.12);
                            }
                        }

                        .multitpinyinbox {
                            color: #333;
                            font-size: 14px;

                            .item {
                                cursor: pointer;
                                line-height: 35px;
                                padding-right:10px;

                                &:hover {
                                    color: #2E4BF2;
                                }
                            }
                        }
                    }
                }
            }
        }
        ::v-deep .el-loading-mask{
            font-size: 30px;
        }
    }
}
.audio_box{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 0;
}
</style>
<style lang="scss">
.el-popover.customdelay {
    background-color: #364251 !important;
    border: none;
}

.el-popover.customdelay .popper__arrow {
    border-top-color: #364251;
}

.el-popover.customdelay .popper__arrow::after {
    content: "";
    border-top-color: #364251;
}

.item_txt {
    line-height: 20px;
}

.delay {
    display: inline-flex;
    align-items: center;

    .item {
        cursor: pointer;
        width: 60px;
        border-radius: 2px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        background: #4d596a;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-right: 10px;
    }

    .iteminput {
        display: flex;
        align-items: center;
        background: #4d596a;
        height: 32px;
        padding: 0 6px;
        border-radius: 2px;

        .iteminputbox {
            width: 46px;

            .el-input__inner {
                width: 100%;
                height: 20px;
                line-height: 20px;
                border-radius: 2px;
                padding: 0;
                text-align: center;
                background-color: #818893;
                font-size: 14px;
                border: none;
                color: #fff;
            }
        }

        .label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-left: 6px;
        }
    }
}

.multitpinyin {
    cursor: pointer;
    position: relative;
    margin: 0 3px;
    padding: 0 0 0 5px;
    border: 1px solid #ff7432;
    color: #fff;
    background-color: rgba(255, 170, 130, 0.4);
    display: inline-block;
    line-height: 1.4;
    .multitpinyinI {
        color: #FF7432;
        padding-left: 8px;
        font-style: normal;
    }
}

.multitpinyin:after {
    content: "";
    transform: rotate(-45deg);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #FF7432;
    border-bottom: none;
    display: inline-block;
    position: relative;
    right: -3px;
    bottom: -7px;
}

.multitnumber {
    cursor: pointer;
    position: relative;
    margin: 0 2px;
    padding: 0 0 0 5px;
    border: 1px solid #32ffdd;
    color: #fff;
    background-color: rgba(50, 255, 228, 0.44);
    display: inline-block;
    line-height: 1.4;
    .multitpinyinI {
        color: #32ffdd;
        padding-left: 8px;
        font-style: normal;
    }
}

.multitnumber:after {
    content: "";
    transform: rotate(-45deg);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #32FFDD;
    border-bottom: none;
    display: inline-block;
    position: relative;
    right: -3px;
    bottom: -7px;
}

.delaypause {
    background: rgba(48, 77, 242, 0.5);
    border-radius: 2px;
    border: 1px solid #2e4bf2;
    padding: 0 9px;
    padding: 0 5px;
    margin: 0 4px;
}</style>
